import React from "react"
import { Link } from "gatsby"

import { withLoginForm } from "./withLoginForm"
import { StyledInput } from "../../../Styled/Form"
import { Submit, Errors, Error, Additional, AdditionalSpan } from "../../Layout/LayoutStyles"

export const LoginForm = withLoginForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    errors,
    forgotURL,
    signUpUrl,
    additionalEmailPlaceholder,
    additionalPasswordPlaceholder,
    additionalForgotPasswordText,
    additionalForgotHighlightWords,
    additionalSignUpText,
    additionalHighlightWords,
    additionalSubmitButton,
    additionalPasswordErrorMessage,
  }): JSX.Element => (
    <form onSubmit={handleSubmit}>
      <StyledInput
        name="email"
        type="email"
        value={data?.email}
        onChange={handleChange}
        placeholder={additionalEmailPlaceholder}
        required
        spacing={"true"}
      />
      <StyledInput
        name="password"
        type="password"
        value={data?.password}
        onChange={handleChange}
        placeholder={additionalPasswordPlaceholder}
        required
        spacing={"true"}
      />
      {errors?.length > 0 && (
        <Errors>
          {errors?.map(({ code, message }) => (
            <Error key={code}>
              <p>{code === "UNIDENTIFIED_CUSTOMER" ? additionalPasswordErrorMessage : message}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Submit wide type={`submit`} colour={"yellow"} disabled={loading}>
        {additionalSubmitButton}
      </Submit>
      <Additional cols={"2"}>
        <Link title={additionalForgotPasswordText} to={forgotURL}>
          <AdditionalSpan>{additionalForgotPasswordText[0]}</AdditionalSpan>
          {additionalForgotHighlightWords ? <AdditionalSpan underline={"true"}>{additionalForgotHighlightWords}</AdditionalSpan> : null}
          {additionalForgotPasswordText.length > 1 ? <AdditionalSpan>{additionalForgotPasswordText?.[1]}</AdditionalSpan> : null}
        </Link>
        <Link to={signUpUrl} title={additionalHighlightWords}>
          <AdditionalSpan>{additionalSignUpText[0]}</AdditionalSpan>
          {additionalHighlightWords ? <AdditionalSpan highlight={"true"}>{additionalHighlightWords}</AdditionalSpan> : null}
          {additionalSignUpText.length > 1 ? <AdditionalSpan>{additionalSignUpText?.[1]}</AdditionalSpan> : null}
        </Link>
      </Additional>
    </form>
  )
)
