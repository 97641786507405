import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../../hooks/useApp"
import { useCustomerLogin } from "../../../../hooks/useCustomer"

export const withLoginForm = Component => ({ name = "LoginForm" }) => {
  const {
    config: {
      settings: {
        routes: { FORGOT, REGISTER },
      },
    },
  } = useApp()

  const { loginCustomer, data, setData, loading, errors } = useCustomerLogin()

  const { login } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_LOGIN {
      login: sanityPageAccountLogin {
        additionalEmailPlaceholder
        additionalPasswordPlaceholder
        additionalForgotPasswordText
        additionalForgotHighlightWords
        additionalSignUpText
        additionalHighlightWords
        additionalSubmitButton
        additionalPasswordErrorMessage
      }
    }
  `)

  const {
    additionalEmailPlaceholder,
    additionalPasswordPlaceholder,
    additionalForgotPasswordText,
    additionalForgotHighlightWords,
    additionalSignUpText,
    additionalHighlightWords,
    additionalSubmitButton,
    additionalPasswordErrorMessage,
  } = login || {}

  const handleSubmit = async event => {
    event.preventDefault()
    await loginCustomer({
      ...data,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }
  const additionalSignUpTextBreakdown = additionalSignUpText.split(additionalHighlightWords)
  const additionalForgotPasswordTextBreakdown = additionalForgotPasswordText.split(additionalForgotHighlightWords)
  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      forgotURL={FORGOT}
      signUpUrl={REGISTER}
      additionalEmailPlaceholder={additionalEmailPlaceholder}
      additionalPasswordPlaceholder={additionalPasswordPlaceholder}
      additionalForgotPasswordText={additionalForgotPasswordTextBreakdown}
      additionalForgotHighlightWords={additionalForgotHighlightWords}
      additionalSignUpText={additionalSignUpTextBreakdown}
      additionalHighlightWords={additionalHighlightWords}
      additionalSubmitButton={additionalSubmitButton}
      additionalPasswordErrorMessage={additionalPasswordErrorMessage}
    />
  )
}
